import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://c4e4b12126b94842af58381cf9749216@o1146287.ingest.sentry.io/4505549494419456",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it. "nobi-cloud@<%= ENV['COMMIT_BRANCH'] %>"
  release: "nobi-cloud",
  integrations: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
